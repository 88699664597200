import {Component, NgModule, Optional} from '@angular/core';
import {
  PortofinoModule, PortofinoUpstairsModule, AuthenticationService,
  NotificationService, MatSnackBarNotificationService, NOTIFICATION_HANDLERS,
  Page, PortofinoComponent, PortofinoService, SidenavService,
  CrudComponent, CustomPageComponent, TextPageComponent,
  UpstairsComponent,
  PageLayout
} from "portofino";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {QuillModule} from "ngx-quill";
import {HttpClientModule, HttpClient} from "@angular/common/http";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {FileInputAccessorModule} from "file-input-accessor";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";

//Customize for your app's locales, if any. As of version 5.1.3, Portofino is translated to Spanish and Italian.
//Additional translations are welcome!
import localeEs from "@angular/common/locales/es";
import localeIt from "@angular/common/locales/it";

registerLocaleData(localeEs);
registerLocaleData(localeIt);

@Component({
  selector: 'app-root',
  template: `<portofino-app appTitle="EIT RM - SAFEME4MINE WebApp" apiRoot="http://localhost:8080/api/"></portofino-app>`
})
export class AppComponent {}

// @Component({
//   selector: 'portofino-welcome',
//   template: `
    // <portofino-page-layout [page]="this">
    //   <ng-template #content>
    //     <img style="display: block;margin-left: auto;margin-right: auto; width: 250px" src="assets/img/sm4m.png" alt="sm4m logo" />

    //     <p>Welcome to the EIT RM SAFEME4MINE web app!</p>
    //     <p>
    //       Use the navigation button
    //       <button title="{{ 'Navigation' | translate }}" type="button" mat-icon-button
    //               (click)="sidenav.toggle()">
    //         <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    //       </button>
    //       to explore the pages.
    //     </p>
    //     <p>
    //       To access the web app you need a username and password that have been provided by FBK. If you lost them,
    //       please drop an e-mail to <b>m [dot] antonini [at] fbk [dot] eu </b> or <b>mmarafini [at] fbk [dot] eu</b>.
    //     </p>
    //     <p>
    //       If you find any bug, please report it to <b>safeme4mine-bugs [at] fbk [dot] eu</b>!
    //     </p>
    //   </ng-template>
    // </portofino-page-layout>`
// })
@Component({
    selector: 'portofino-welcome',
    templateUrl: 'home.html',
    styleUrls: ['home.scss']
  })
@PortofinoComponent({ name: 'welcome' })
export class WelcomeComponent extends Page {
  constructor(
    portofino: PortofinoService, http: HttpClient, router: Router,
    @Optional() route: ActivatedRoute, authenticationService: AuthenticationService,
    notificationService: NotificationService, translate: TranslateService,
    public sidenav: SidenavService) {
    super(portofino, http, router, route, authenticationService, notificationService, translate);
  }
}

@NgModule({
  declarations: [AppComponent, WelcomeComponent],
  providers: [
    { provide: NOTIFICATION_HANDLERS, useClass: MatSnackBarNotificationService, multi: true }
  ],
  imports: [
    RouterModule.forRoot([...PortofinoModule.defaultRoutes()], PortofinoModule.defaultRouterConfig()),
    PortofinoModule, PortofinoUpstairsModule,
    BrowserModule, BrowserAnimationsModule, FlexLayoutModule, FormsModule, HttpClientModule, ReactiveFormsModule,
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule,
    MatIconModule, MatInputModule, MatMenuModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatSidenavModule,
    MatSnackBarModule, MatSortModule, MatTableModule, MatToolbarModule, MatMomentDateModule,
    FileInputAccessorModule, QuillModule.forRoot(), TranslateModule.forRoot()],
  bootstrap: [AppComponent]
})
export class AppModule {
  // It's necessary to spell the components used in the application here, otherwise Angular (Ivy) tree-shakes them.
  // See https://github.com/angular/angular/issues/33715#issuecomment-617606494 and
  // https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [
    CrudComponent, CustomPageComponent, TextPageComponent, UpstairsComponent, WelcomeComponent ];
}
