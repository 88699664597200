<!-- Release License
     Copyright (C) Fondazione Bruno Kessler - All Rights Reserved
     Unauthorized copying of this file, via any medium is strictly prohibited
     Proprietary and confidential
     OpenIoT <open-iot@fbk.eu>, December 2021
-->

<portofino-page-layout [page]="this">
  <ng-template #content>
    <img style="display: block;margin-left: auto;margin-right: auto; width: 250px" src="assets/img/sm4m.png" alt="sm4m logo" />

    <p>Welcome to the EIT RM SAFEME4MINE web app!</p>
    <p>
      Use the navigation button
      <button title="{{ 'Navigation' | translate }}" type="button" mat-icon-button
              (click)="sidenav.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      to explore the pages.
    </p>
    <p>
      To access the web app you need a username and password that have been provided by the administrator. If you lost them,
      please drop an e-mail to <b>admin [at] example [dot] com </b>.
    </p>
    <p>
      If you find any bug, please report it to <b>bugs [at] example [dot] com</b>!
    </p>
  </ng-template>
</portofino-page-layout>
